import * as types from "../mutation-types";
export default {
  state: {
    newProductSchema: {},
    newProductPricingSchema: {},
    storeProducts: [],
    product: {},
    productSchema: {},
    userProducts: [],
    productTaxCodeMaps: [],
  },
  getters: {
    newProductSchema: (state) => state.newProductSchema,
    newProductPricingSchema: (state) => state.newProductPricingSchema,
    storeProducts: (state) => state.storeProducts,
    product: (state) => state.product,
    userProducts: (state) => state.userProducts,
    productTaxCodeMaps: (state) => state.productTaxCodeMaps,
  },
  mutations: {
    [types.PRODUCTS.SET_NEW_PRODUCT_SCHEMA](state, newProductSchema) {
      state.newProductSchema = newProductSchema;
    },
    [types.PRODUCTS.SET_NEW_PRODUCT_PRICING_SCHEMA](
      state,
      newProductPricingSchema
    ) {
      state.newProductPricingSchema = newProductPricingSchema;
    },
    [types.PRODUCTS.SET_STORE_PRODUCTS](state, storeProducts) {
      state.storeProducts = storeProducts;
    },
    [types.PRODUCTS.SET_PRODUCT](state, product) {
      state.product = product;
    },
    [types.PRODUCTS.SET_USER_PRODUCTS](state, userProducts) {
      state.userProducts = userProducts;
    },
    [types.PRODUCTS.SET_PRODUCT_TAX_CODE_MAPS](state, productTaxCodeMaps) {
      state.productTaxCodeMaps = productTaxCodeMaps;
    },
  },
  actions: {
    loadProductsForStore(ctx) {
      let appContextOrg = ctx.getters.appContextOrg;
      if (appContextOrg["id"]) {
        vDo(
          "product/org/get_products_for_store",
          { org_id: appContextOrg["id"] },
          (response) => {
            ctx.commit(types.PRODUCTS.SET_STORE_PRODUCTS, response["data"]);
          }
        );
      }
    },
    clearProduct(ctx) {
      ctx.commit(types.PRODUCTS.SET_PRODUCT, {});
    },
    loadProduct(ctx, productID) {
      vDo("product/get_product", { product_id: productID }, (response) => {
        ctx.commit(types.PRODUCTS.SET_PRODUCT, response["data"]);
      });
    },
    loadUserProducts(ctx) {
      vDo("userprofile/product/get_product_assets", {}, (response) => {
        ctx.commit(types.PRODUCTS.SET_USER_PRODUCTS, response.data);
      });
    },
    loadNewProductSchema(ctx, includePricing) {
      vDo(
        "product/get_new_product_schema",
        { include_pricing: includePricing },
        (response) => {
          ctx.commit(types.PRODUCTS.SET_NEW_PRODUCT_SCHEMA, response.data);
        }
      );
    },
    loadNewProductPricingSchema(ctx) {
      vDo("product/get_new_product_pricing_schema", {}, (response) => {
        ctx.commit(
          types.PRODUCTS.SET_NEW_PRODUCT_PRICING_SCHEMA,
          response.data
        );
      });
    },
    loadProductTaxCodeMaps(ctx) {
      if (ctx.state.productTaxCodeMaps.length == 0) {
        vDo("integration/avalara/get_product_tax_code_maps", {}, (response) => {
          ctx.commit(types.PRODUCTS.SET_PRODUCT_TAX_CODE_MAPS, response.data);
        });
      }
    },
  },
};
