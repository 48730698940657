<template>
  <div class="mt-4 mb-2">
    <span class="spanSmallGrey">
      {{ `Venue Seating Type` }}
    </span>
    <v-radio-group
      :disabled="eventProducts.length > 0 || seatingChartSelected"
      class="ma-0 pa-0 mt-4"
      v-model="seatingType"
      row
    >
      <v-radio
        color="velocityBlue"
        label="General Admission"
        value="general_admission"
      ></v-radio>
      <v-radio
        color="velocityBlue"
        label="Assigned Seating"
        value="assigned_seating"
      ></v-radio>
    </v-radio-group>
    <div v-if="seatingType == 'general_admission'">
      <TicketTypes />
    </div>
    <SeatsChartSelector
      v-if="seatingType == 'assigned_seating'"
      @setSelectedChart="setMeta"
      :meta="meta"
    />
  </div>
</template>

<script>
import SeatsChartSelector from "./SeatsChartSelector.vue";
import TicketTypes from "./TicketTypes.vue";
export default {
  components: { SeatsChartSelector, TicketTypes },
  props: { meta: Object },
  data: () => ({
    seatingType: "general_admission",
    seatingChartSelected: false,
  }),
  mounted() {
    this.$store.dispatch("loadNewProductSchema");
  },
  methods: {
    setMeta(meta) {
      if (
        this.meta.integrations &&
        this.meta.integrations.sio &&
        this.meta.integrations.sio.chart
      ) {
        this.seatingChartSelected = true;
      }
      this.$emit("setMeta", meta);
    },
  },
  computed: {
    eventProducts() {
      return this.$store.getters.eventProducts;
    },
  },
  watch: {
    meta(newVal) {
      if (newVal) {
        if (newVal["seating_type"]) {
          this.seatingType = newVal["seating_type"];
        }
      }
    },
    seatingType(newVal) {
      if (newVal) {
        this.meta["seating_type"] = newVal;
        this.setMeta(this.meta);
      }
    },
  },
};
</script>

<style></style>
