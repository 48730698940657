<template>
  <div>
    <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          outlined
          elevation="0"
          :class="`${
            miniVariant ? 'transparent ma-0 pa-0' : 'transparent'
          } mt-2 d-flex align-center`"
        >
          <span v-if="!miniVariant" style="max-width: 32px" class="mr-2">
            <v-img :src="meta.logo_image" />
          </span>
          <span class="paragraph1">{{
            miniVariant ? firstAndLastChar.toUpperCase() : truncate(orgName)
          }}</span>
          <v-icon v-if="chiefedOrgs.length > 1" :small="miniVariant">
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title v-if="chiefedOrgs.length > 1" class="ma-0 pa-0 pa-4">
          <span class="textSubtitle">Switch Organization</span>
        </v-card-title>
        <v-divider />
        <v-container class="org-list-container">
          <v-row
            v-for="org in chiefedOrgs"
            :key="org.id"
            @click="switchAppOrg(org)"
            class="bottomBorder px-3 py-2 ma-0"
            align="center"
            style="cursor: pointer"
          >
            <v-col cols="3">
              <v-avatar
                style="border-radius: 10px !important"
                color="velocityBlue"
                class="white--text"
                size="40"
                tile
              >
                {{ getChiefedOrgName(org)[0] }}
              </v-avatar>
            </v-col>
            <v-col cols="9">
              <v-flex class="d-flex flex-column">
                <span class="textParagraph1 font-weight-bold">
                  {{ getChiefedOrgName(org) }}
                </span>
                <span v-if="org.email" class="textCaption" color="grey">
                  {{ org.email }}
                </span>
              </v-flex>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { truncateString } from "@/utility/helperFunctions";
export default {
  props: {
    miniVariant: Boolean,
  },
  data() {
    return {
      meta: {},
      data: {},
      orgName: "",
    };
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    truncate(string) {
      return truncateString(string, 15);
    },
    initializeData() {
      if (this.appContextOrg["id"]) {
        this.meta = this.appContextOrg.meta;
        this.data = this.appContextOrg.data;
        this.orgName = this.meta["name"] || this.appContextOrg["id"];
      }
    },
    getChiefedOrgName(org) {
      return org["name"] ? org["name"] : org["id"];
    },
    switchAppOrg(org) {
      if (this.appContextOrg.id != org["id"]) {
        this.$store.dispatch("loadAppContextOrg", org["id"]);
      }
    },
  },
  computed: {
    loadedTribe() {
      return this.$store.getters.loadedTribe;
    },
    placeholderImage() {
      let orgMeta = this.$store.getters.loadedTribe.meta;
      if (orgMeta && orgMeta.logo_image) {
        return orgMeta.logo_image;
      } else {
        return this.$store.getters.velocityLogo;
      }
    },
    chiefedOrgs() {
      return this.$store.getters.chiefedOrgs;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    appContextOrg() {
      return this.$store.getters.appContextOrg;
    },
    firstAndLastChar() {
      if (this.orgName) {
        return `${this.orgName[0]}${this.orgName[this.orgName.length - 1]}`;
      } else {
        return ". .";
      }
    },
  },
  watch: {
    appContextOrg(newVal) {
      this.initializeData();
    },
    loadedTribe(newVal) {
      this.initializeData();
    },
    chiefedOrgs(newVal) {},
  },
};
</script>

<style scoped>
.btnShadow {
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 1px 1px -2px rgba(0, 0, 0, 0.05),
    0px 0px 0px 0px rgba(0, 0, 0, 0.05);
}
::v-deep
  .v-card
  > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar):not(.v-row) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.org-list-container {
  overflow-y: auto;
  max-height: 50vh;
}

@media (max-width: 600px) {
  .org-list-container {
    max-height: 90vh;
  }
}

.v-row {
  flex-shrink: 0;
}
</style>
