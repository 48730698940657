import * as types from "../mutation-types";

export default {
  state: {
    bundles: [],
    bundle: {},
    bundleSchema: {},
    userBundles: [],
    userStandaloneAssets: [],
  },
  getters: {
    bundles: (state) => state.bundles,
    bundle: (state) => state.bundle,
    bundleSchema: (state) => state.bundleSchema,
    userBundles: (state) => state.userBundles,
    userStandaloneAssets: (state) => state.userStandaloneAssets,
  },
  mutations: {
    [types.STORE.SET_BUNDLE_SCHEMA](state, bundleSchema) {
      state.bundleSchema = bundleSchema;
    },
    [types.STORE.SET_BUNDLES](state, bundles) {
      state.bundles = bundles;
    },
    [types.STORE.SET_BUNDLE](state, bundle) {
      bundle["active"] = bundle["active"] == "true" ? true : false;
      state.bundle = bundle;
    },
    [types.STORE.SET_USER_BUNDLES](state, userBundles) {
      state.userBundles = userBundles;
    },
    [types.STORE.SET_USER_STANDALONE_ASSETS](state, userStandaloneAssets) {
      state.userStandaloneAssets = userStandaloneAssets;
    },
  },
  actions: {
    //Bundles Start
    //-----------------------------------
    loadBundles(ctx) {
      let appContextOrg = ctx.getters.appContextOrg;
      if (appContextOrg["id"]) {
        vDo(
          "bundles/get_bundles_for_org",
          { org_id: appContextOrg["id"] },
          (response) => {
            ctx.commit(types.STORE.SET_BUNDLES, response["data"]);
          }
        );
      }
    },
    clearBundle(ctx) {
      ctx.commit(types.STORE.SET_BUNDLE, {});
    },
    loadBundle(ctx, bundleID) {
      vDo("bundles/get_bundle", { bundle_id: bundleID }, (response) => {
        ctx.commit(types.STORE.SET_BUNDLE, response["data"]);
      });
    },
    loadNewBundle(ctx) {
      vDo("bundles/get_new_bundle_schema", {}, (response) => {
        ctx.commit(types.STORE.SET_BUNDLE_SCHEMA, response["data"]);
      });
    },
    loadUserBundles(ctx) {
      vDo("userprofile/bundle/get_bundle_assets", {}, (response) => {
        ctx.commit(types.STORE.SET_USER_BUNDLES, response.data);
      });
    },
    loadUserStandaloneProducts(ctx) {
      vDo("userprofile/product/get_standalone_assets", {}, (response) => {
        ctx.commit(types.STORE.SET_USER_STANDALONE_ASSETS, response.data);
      });
    },
  },
};
