import Vue from "vue";
import VueRouter from "vue-router";
// import OrgEvents from '../views/events/OrgEvents.vue'
// import OrgEventsOverview from '../views/events/OrgEventsOverview.vue'
// import EventBuilderExpanded from '../views/events/EventBuilderExpanded.vue'
// import VenuePortal from '../views/events/VenuePortal.vue'
// import Calendar from '../views/events/Calendar.vue'
// import NotFound404 from '../views/app/NotFound404.vue'
// import Checkout from '../views/marketplace/Checkout.vue'
// import Marketplace from '../views/marketplace/Marketplace.vue'
// import EventDetails from '../views/marketplace/EventDetails.vue'
// import BundleDetails from '../views/marketplace/bundle/BundleDetails.vue'
// import Receipt from '../views/marketplace/Receipt.vue'
// import MyWallet from '../views/user/MyWallet.vue'
// import MyEventTickets from '../views/user/MyEventTickets.vue'
// import Profile from '../views/user/Profile.vue'
// import PromoCodes from '../views/loyalty/promo-codes/PromoCodeSearch.vue'
// import PromoCode from '../views/loyalty/promo-codes/PromoCode.vue'
// import Bundles from '../views/store/bundles/BundleSearch.vue'
// import Bundle from '../views/store/bundles/Bundle.vue'
// import Products from '../views/store/products/ProductSearch.vue'
// import ProductBuilder from '../views/store/products/ProductBuilder.vue'
// import BundleRedemptionEventSearch from '../views/user/bundles/BundleRedemptionEventSearch'
// import Waitlist from '../views/placeholders/Waitlist.vue'
// import OrgPayments from '../views/organization/OrgPayments.vue'
// import OrgSettings from '../views/organization/OrgSettings.vue'
// import TransactionDetails from '../views/analytics/TransactionDetails.vue'
// import Create from '../views/app/Create.vue';
// //ANALYTICS
// import Dashboard from '../views/analytics/Dashboard.vue'
// import Reports from '../views/analytics/Reports.vue'

Vue.use(VueRouter);

const routes = [
  //ROUTES FOR DEFAULT DASHBOARD
  {
    path: "/overview/payments",
    name: "Payments",
    component: () => import("../views/organization/OrgPayments.vue"),
    meta: {},
  },
  {
    path: "/overview/transactiondetails/:txnID",
    name: "Transaction Details",
    component: () => import("../views/analytics/TransactionDetails.vue"),
    meta: {},
  },
  {
    path: "/overview/settings",
    name: "Settings",
    component: () => import("../views/organization/OrgSettings.vue"),
    meta: {},
  },
  //ROUTES FOR EVENT DASHBOARD APP
  {
    path: "/ticketing",
    name: "OrgEventsOverview",
    component: () => import("../views/events/OrgEventsOverview.vue"),
    meta: {
      navigationItems: ["createEvent"],
    },
  },
  {
    path: "/ticketing/events",
    name: "OrgEvents",
    component: () => import("../views/events/OrgEvents.vue"),
    meta: {
      navigationItems: ["createEvent"],
    },
  },
  {
    path: "/ticketing/venues",
    name: "Venue Portal",
    component: () => import("../views/events/VenuePortal.vue"),
    meta: {
      navigationItems: ["createEvent"],
    },
  },
  {
    path: "/ticketing/eventbuilder/:eventID?/:tab?",
    name: "EventBuilderExpanded",
    component: () => import("../views/events/EventBuilderExpanded.vue"),
    meta: {
      title: "Event Details",
      navigationItems: ["publishEvent"],
    },
  },
  {
    path: "/ticketing/calendar",
    name: "Calendar",
    component: () => import("../views/events/Calendar.vue"),
    meta: {
      navigationItems: ["createEvent"],
    },
  },
  // ----------------------------------

  //ROUTES FOR TICKET MARKETPLACE APP
  {
    name: "Marketplace Org",
    path: "/org/:organization?",
    component: () => import("../views/marketplace/Marketplace.vue"),
  },
  {
    name: "Marketplace",
    path: "/marketplace/search/:organization?",
    component: () => import("../views/marketplace/Marketplace.vue"),
  },
  {
    name: "Event Details",
    path: "/marketplace/eventdetails/:eventID?",
    component: () => import("../views/marketplace/EventDetails.vue"),
  },
  {
    name: "Bundle Details",
    path: "/marketplace/bundledetails/:bundleID?",
    component: () => import("../views/marketplace/bundle/BundleDetails.vue"),
  },
  {
    path: "/marketplace/checkout",
    name: "Checkout",
    component: () => import("../views/marketplace/Checkout.vue"),
  },
  {
    path: "/marketplace/receipt/:payment_intent?/:txnID?",
    name: "Receipt",
    component: () => import("../views/marketplace/Receipt.vue"),
  },
  {
    path: "/marketplace/my-wallet",
    name: "My Wallet",
    component: () => import("../views/user/MyWallet.vue"),
  },
  {
    path: "/marketplace/event-tickets/:eventID",
    name: "My Event Tickets",
    component: () => import("../views/user/MyEventTickets.vue"),
  },
  ////ROUTES FOR BUNDLE REDEMPTION
  {
    path: "/bundle/redeem-events/:assetID",
    name: "Bundle Redemption Event Search",
    component: () =>
      import("../views/user/bundles/BundleRedemptionEventSearch.vue"),
  },
  //// ----------------------------------
  // ----------------------------------
  // ROUTES FOR PARKING APP
  {
    name: "Parking Overview",
    path: "/parking",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "My Locations",
    path: "/parking/locations",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Parking Settings",
    path: "/parking/settings",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Parking Analytics",
    path: "/parking/analytics",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  // ----------------------------------
  // ROUTES FOR LOYALTY
  {
    name: "Loyalty",
    path: "/loyalty",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Experiences",
    path: "/loyalty/experiences",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Settings",
    path: "/loyalty/settings",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  // ----------------------------------
  // ROUTES FOR Promo Codes
  {
    name: "Promo Codes",
    path: "/loyalty/promo-codes",
    component: () => import("../views/loyalty/promo-codes/PromoCodeSearch.vue"),
  },
  {
    name: "Promo Code",
    path: "/loyalty/promo-code/:promoCodeID",
    component: () => import("../views/loyalty/promo-codes/PromoCode.vue"),
  },
  // ----------------------------------
  // ROUTES FOR STORE
  {
    name: "Bundles",
    path: "/store/bundles",
    component: () => import("../views/store/bundles/BundleSearch.vue"),
  },
  {
    name: "Bundle",
    path: "/store/bundle/:bundleID/:selectedTab?",
    component: () => import("../views/store/bundles/Bundle.vue"),
  },
  {
    name: "Products",
    path: "/store/products",
    component: () => import("../views/store/products/ProductSearch.vue"),
  },
  {
    name: "Product",
    path: "/store/product/:productID/:selectedTab?",
    component: () => import("../views/store/products/ProductBuilder.vue"),
  },
  {
    name: "Payment Readers",
    path: "/store/payment-readers",
    component: () =>
      import("../views/store/payment-readers/PaymentReaders.vue"),
  },
  {
    name: "Payment Reader",
    path: "/store/payment-reader/:paymentReaderID",
    component: () =>
      import("../views/store/payment-readers/PaymentReaderBuilder.vue"),
  },
  // ----------------------------------
  // ROUTES FOR MARKETING
  {
    name: "Campaigns",
    path: "/marketing/campaigns",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Impressions",
    path: "/marketing/impressions",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Settings",
    path: "/marketing/settings",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  // ----------------------------------
  // ROUTES FOR LODGING
  {
    name: "Room Management",
    path: "/lodging/room_management",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Housekeeping",
    path: "/lodging/housekeeping",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Settings",
    path: "/lodging/settings",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  // ----------------------------------
  // ROUTES FOR DINING
  {
    name: "Restaraunts",
    path: "/dining/restaraunts",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Inventory",
    path: "/dining/inventory",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Settings",
    path: "/dining/settings",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  // ----------------------------------
  // ROUTES FOR ANALYTICS
  {
    name: "Dashboard",
    path: "/analytics/dashboard",
    component: () => import("../views/analytics/Dashboard.vue"),
  },
  {
    name: "Reports",
    path: "/analytics/reports",
    component: () => import("../views/analytics/Reports.vue"),
  },
  // ----------------------------------
  // ROUTES FOR ATTRACTIONS
  {
    name: "Dashboard",
    path: "/attractions/dashboard",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Maintenance",
    path: "/attractions/maintenance",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  {
    name: "Settings",
    path: "/attractions/settings",
    component: () => import("../views/placeholders/Waitlist.vue"),
  },
  // ----------------------------------
  //ROUTES FOR User/Org Creator
  {
    path: "/create",
    name: "Create",
    component: () => import("../views/app/Create.vue"),
  },
  //ROUTES FOR COMMON
  {
    path: "/profile/:tribeID?",
    name: "Profile",
    component: () => import("../views/user/Profile.vue"),
  },
  //ROUTES FOR VELOCITY ADMIN CONTROL
  {
    path: "/admin/user-management",
    name: "User Management",
    component: () => import("../views/vac/users/UserDashboard.vue"),
  },
  {
    path: "/admin/org-management",
    name: "Org Management",
    component: () => import("../views/vac/orgs/OrgDashboard.vue"),
  },
  // ----------------------------------
  // if any other route
  { path: "/", redirect: "/marketplace/search" }, // !!! route entrypoint!!!
  { path: "*", component: () => import("../views/app/NotFound404.vue") },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {});
};

export default router;
